<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

        <!-- <div class="side-menu">
          
          <aside class="menu">
            <p class="menu-label">Menu</p>
            <ul class="menu-list">
              <li><a class="is-active">All User Guides</a></li>
              <li><router-link to="user-guide-vessels">Adding Vessels</router-link></li>
              <li><router-link to="user-guide-fleets">How to use Fleets</router-link></li>
              <li><router-link to="user-guide-crew">Adding Crew</router-link></li>
              <li><router-link to="user-guide-app">Using Our App</router-link></li>
              <li><router-link to="user-guide-obligations">Safety Obligations</router-link></li>
              <li><router-link to="user-guide-subscriptions">Subscriptions</router-link></li>
            </ul>
          </aside>

        </div> -->

        <user-guide-side-menu sentPath="user-guide-start" />

        <user-guide-top-menu sentPath="user-guide-start" />



        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>OffshoreSMS User Guides</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/devices.jpg" /></div>
          <div class="side-text right">
            <h5>How to use these guides</h5>
            <p>We've broken these guides down into different sections to make it easier to use. Simply click on a menu item to view its contents.</p>
            <p>You can come back to this guide at any time by clicking <strong>All User Guides</strong> in the top right menu.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/rocket.jpg" /></div>
          <div class="side-text left">
            <h5>Get help</h5>
            <p>We are here to help you and our support is always FREE! Please feel free to call us on <a href="tel:0447-139-382">0447 139 382</a> or email us on <a href="mailto:help@offshoresms.com.au?subject=Help enquiry from Offshore SMS website">help@offshoresms.com.au</a>.</p>
            <p>If you'd like to leave some feedback, please <router-link to="feedback">click here</router-link>.</p>
          </div>
          <div class="clear"></div>
          <hr>

          <div class="buttons">
            <router-link to="user-guide-vessels" class="button is-primary">Next:&nbsp; Adding Vessels &nbsp; <font-awesome-icon icon="chevron-right" /></router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideStart',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
    //   toggleDropdown: function() {
    //     this.dropdownActive = !this.dropdownActive;
    //   }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }
//   .dropdown-wrapper {
//     display: none;
//     .dropdown { 
//       width: 100%;
//       .dropdown-trigger {
//         width: 100%;
//         .button {
//           width: 100%;
//           &:focus {
//             outline: none;
//             border-color: #dbdbdb;
//           }
//           .is-small {
//             svg {
//               font-size: 0.6rem;
//             }
//           }
//         }
//       }
//       .dropdown-menu {
//         width: 100%;
//         .dropdown-content {
//           .router-link-active {
//             background: #eee;
//           }
//         }
//       }
//     }
//   }
  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






