<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

        <!-- <div class="side-menu">
          
          <aside class="menu">
            <p class="menu-label">Menu</p>
            <ul class="menu-list">
              <li><router-link to="user-guide-start">All User Guides</router-link></li>
              <li><a class="is-active">Adding Vessels</a></li>
              <li><router-link to="user-guide-fleets">How to use Fleets</router-link></li>
              <li><router-link to="user-guide-crew">Adding Crew</router-link></li>
              <li><router-link to="user-guide-app">Using Our App</router-link></li>
              <li><router-link to="user-guide-obligations">Safety Obligations</router-link></li>
              <li><router-link to="user-guide-subscriptions">Subscriptions</router-link></li>
            </ul>
          </aside>

        </div> -->

        <user-guide-side-menu sentPath="user-guide-vessels" />

        <user-guide-top-menu sentPath="user-guide-vessels" />



        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>Adding Vessels</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/boat-3.jpg" /></div>
          <div class="side-text right">
            <h5>Owner Operators and basic SMS users</h5>
            <p>If you are an owner operator, or if you have more than one vessel but only require a simple safety management system, then you don't need to use our Fleet feature.</p>
            <p>On your Dashboard, click on the <a class="button is-info inline">My Vessels</a> tab at the top, and add your vessels.</p>
            <p>On the basic SMS plan you can still do the required logs to be AMSA compliant, like startup checks, crew inductions, maintenance and incident reporting, plus our ever-useful Ship's logs.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/trailer.jpg" /></div>
          <div class="side-text left">
            <h5>Multiple vessel Fleet users</h5>
            <p>If on the other hand you own or manage a fleet of multiple vessels, or if you employ skippers and crew on your vessels, then you really should take advantage of our Fleet plan. For less than <strong>$5/vessel per week</strong>, you can have all the amazing fleet features like:</p>
            <div class="spacer s20"></div>
            <ul class="features">
              <li><font-awesome-icon icon="list-ul" />&nbsp; Fleet Management Dashboard</li>
              <li><font-awesome-icon icon="cloud" />&nbsp; Real-time Activity Logs</li>
              <li><font-awesome-icon icon="user-graduate" />&nbsp; Monitor Vessel SMS Compliance</li>
              <li><font-awesome-icon icon="life-ring" />&nbsp; Expired Safety Equipment Register</li>
              <li><font-awesome-icon icon="wrench" />&nbsp; Scheduled Maintenance and Drills</li>
              <li><font-awesome-icon icon="cog" />&nbsp; Log engine and equipment hours</li>
              <li><font-awesome-icon icon="gas-pump" />&nbsp; Fuel and oil logs</li>
              <li><font-awesome-icon icon="times" />&nbsp; Real time defect Register</li>
              <li><font-awesome-icon icon="users" />&nbsp; Passenger manifests and head counts</li>
              <li><font-awesome-icon icon="stopwatch" />&nbsp; New timed activities</li>
              <li><font-awesome-icon icon="search" />&nbsp; Print out reports as PDFs</li>
              <li><font-awesome-icon icon="clipboard-list" />&nbsp; Activity, shutdown &amp; crew change logs</li>
              <li><font-awesome-icon icon="ship" />&nbsp; Unlimited custom logs</li>
              <li><font-awesome-icon icon="pen" />&nbsp; Real time signed crew inductions</li>
              <li><font-awesome-icon icon="ship" />&nbsp; Free Help and Support</li>
            </ul>
            <hr>
          </div>
          <div class="clear"></div>

          <div class="buttons">
            <router-link to="user-guide-fleets" class="button is-primary">Next:&nbsp; Using Fleets &nbsp; <font-awesome-icon icon="chevron-right" /></router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideVessels',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
      // toggleDropdown: function() {
      //   this.dropdownActive = !this.dropdownActive;
      // }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }

  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
    .button {
      &.inline {
        padding: 2px 8px;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.9rem;
      }
    }
  }

  ul.features {
    li {
      font-size: 1.1rem;
      margin-bottom: 6px;
      background: #f8f8f8;
      padding: 10px;
      color: steelblue;
      svg {
        font-size: 1.1rem;
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






