<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

      <user-guide-side-menu sentPath="user-guide-subscriptions" />

      <user-guide-top-menu sentPath="user-guide-subscriptions" />


        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>Vessel Subscriptions</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/mountain.jpg" /></div>
          <div class="side-text right">
            <h5>OffshoreSMS is great value!</h5>
            <p>We firmly believe that we have the best products available to manage any commercial vessel in Australia, from a single operator fishing boat to a large commercial vessel fleet.</p>
            <p>We've also worked hard to make our SMS tools the best value you'll find anywhere, with a standard vessel SMS from <strong>less than $3</strong> per week per vessel and a Fleet vessel SMS from <strong>less than $5</strong> per vessel per week.</p>
            <p>Which you choose depends on how much information you would like to capture, and how big your operation is. If you would like help deciding which is best for you, please feel free to call us on <a href="tel:0447-139-382">0447 139 382</a> or email us on <a href="mailto:help@offshoresms.com.au?subject=Help enquiry from Offshore SMS website">help@offshoresms.com.au</a>.</p>
            <div class="spacer s20"></div>
            <div class="buttons">
              <router-link to="pricing" class="button is-skip is-medium">Compare our Features</router-link>
            </div>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/handshake.jpg" /></div>
          <div class="side-text left">
            <h5>Every vessel gets a Free Trial</h5>
            <p>Every vessel that you add comes with a full access 7 Day FREE Trial! We want you to be 100% sure that you're happy with OffshoreSMS. At any time during of after the trial, you can upgrade to the full paid subscription by clicking on the <a class="button is-warning inline">Manage Subscriptions</a> button on the My Vessels page.</p>
            <p>Our subscriotions are billed annually at <strong>$149 per vessel per year</strong> for the standard vessels, or <strong>$249 per vessel per year</strong> for fleet vessels.</p>
          </div>
          <div class="clear"></div>

          <hr>

          <div class="buttons">
            <router-link to="user-guide-start" class="button is-info">Back to Start</router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideSubscriptions',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
      // toggleDropdown: function() {
      //   this.dropdownActive = !this.dropdownActive;
      // }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }
  // .dropdown-wrapper {
  //   display: none;
  //   .dropdown { 
  //     width: 100%;
  //     .dropdown-trigger {
  //       width: 100%;
  //       .button {
  //         width: 100%;
  //         &:focus {
  //           outline: none;
  //           border-color: #dbdbdb;
  //         }
  //         .is-small {
  //           svg {
  //             font-size: 0.6rem;
  //           }
  //         }
  //       }
  //     }
  //     .dropdown-menu {
  //       width: 100%;
  //       .dropdown-content {
  //         .router-link-active {
  //           background: #eee;
  //         }
  //       }
  //     }
  //   }
  // }
  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
      &.italic {
        font-style: italic;
      }
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
    .button {
      &.inline {
        padding: 2px 8px;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.9rem;
      }
      &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #ff7878;
      }
    }
  }

  ul.features {
    li {
      font-size: 1.1rem;
      margin-bottom: 6px;
      background: #f8f8f8;
      padding: 15px;
      color: dodgerblue;
      svg {
        font-size: 1.1rem;
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






