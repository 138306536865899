<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

      <user-guide-side-menu sentPath="user-guide-crew" />

      <user-guide-top-menu sentPath="user-guide-crew" />



        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>Adding Crew</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/crew.jpg" /></div>
          <div class="side-text right">
            <h5>Get your entire team to create a Free profile on OffshoreSMS</h5>
            <p>Your crew is the most valuable asset on your vessel. Getting your crew onto OffshoreSMS with their own personal profile empowers them to get involved in the safe management of your vessel, and puts some responsibility back onto them to make your vessel a safer place.</p>
            <p>To add new crew, they first need to have downloaded the OffshoreSMS app and created their own FREE profile. Then, under your list of vessels, click on the <a class="button is-skip inline">Manage Crew</a> button. Search for an OffshoreSMS user and add them as crew.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/hand-on-phone.jpg" /></div>
          <div class="side-text left">
            <h5>Encourage your crew to keep their profiles up to date</h5>
            <p>In a traditional paper SMS, it is up to the vessel owner to follow up with crew regularly to check if any personal details have changed.</p>
            <p>On OffshoreSMS, it is super easy for crew to keep their profile information updated in the App, including personal and emergency contact info and their Qualifications.</p>
            <p>Encourage your team to take responsibility for their own data.</p>
            <p>They can also track all of their seatime for free in the app, and log it per vessel with the same format required for AMSA's Form 771 Sea time record.</p>
          </div>
          <div class="clear"></div>

          <hr>
          <div class="side-icon left"><img src="@/assets/icons/wheel.jpg" /></div>
          <div class="side-text right">
            <h5>Set a crew member as a Master on a Fleet vessel</h5>
            <p>When a fleet crew member is set as Master by changing their role, they then have the ability to access a full list of Fleet vessel logs, including:</p>
            <div class="spacer s20"></div>
            <ul class="features">
              <li><font-awesome-icon icon="ship" />&nbsp; Ship's Logs</li>
              <li><font-awesome-icon icon="list" />&nbsp; Daily Startup Logs</li>
              <li><font-awesome-icon icon="gas-pump" />&nbsp; Fuel &amp; Oils Logs</li>
              <li><font-awesome-icon icon="wrench" />&nbsp; Scheduled Maintenance and Drills</li>
              <li><font-awesome-icon icon="users" />&nbsp; New Crew Inductions</li>
              <li><font-awesome-icon icon="exclamation-triangle" />&nbsp; Incident &amp; Near Miss Reports</li>
              <li><font-awesome-icon icon="times" />&nbsp; Defect Logs</li>
              <li><font-awesome-icon icon="users" />&nbsp; Crew Change Logs</li>
              <li><font-awesome-icon icon="ship" />&nbsp; Trip Activity Logs</li>
              <li><font-awesome-icon icon="home" />&nbsp; Shutdown Logs</li>
              <li><font-awesome-icon icon="stopwatch" />&nbsp; Timed Activities</li>
              <li><font-awesome-icon icon="life-ring" />&nbsp; Drills &amp; Schedules</li>
              <li><font-awesome-icon icon="pen" />&nbsp; All Custom Logs</li>
              <li><font-awesome-icon icon="users" />&nbsp; Passenger Manifests &amp; Head Count Logs</li>
            </ul>
            <div class="spacer s20"></div>
            <p>Fleet vessel Masters can also add and remve crew, view vessel alerts and more. Most importantly, vessel Masters cannot edit the content of the safety management iteself or edit the checklists use din each log. This must be done by a Fleet admin in the Fleet dashboard.</p>
          </div>
          <div class="clear"></div>

          <hr>

          <div class="buttons">
            <router-link to="user-guide-app" class="button is-primary">Next:&nbsp; Using our app &nbsp; <font-awesome-icon icon="chevron-right" /></router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideCrew',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
      // toggleDropdown: function() {
      //   this.dropdownActive = !this.dropdownActive;
      // }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }
  // .dropdown-wrapper {
  //   display: none;
  //   .dropdown { 
  //     width: 100%;
  //     .dropdown-trigger {
  //       width: 100%;
  //       .button {
  //         width: 100%;
  //         &:focus {
  //           outline: none;
  //           border-color: #dbdbdb;
  //         }
  //         .is-small {
  //           svg {
  //             font-size: 0.6rem;
  //           }
  //         }
  //       }
  //     }
  //     .dropdown-menu {
  //       width: 100%;
  //       .dropdown-content {
  //         .router-link-active {
  //           background: #eee;
  //         }
  //       }
  //     }
  //   }
  // }
  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
      &.italic {
        font-style: italic;
      }
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
    .button {
      &.inline {
        padding: 2px 8px;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.9rem;
      }
      &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #ff7878;
      }
    }
  }

  ul.features {
    li {
      font-size: 1.1rem;
      margin-bottom: 6px;
      background: #f8f8f8;
      padding: 10px;
      color: steelblue;
      svg {
        font-size: 1.1rem;
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






