<template>

    <div class="dropdown-wrapper">
        <!-- <div @click="toggleDropdown" class="dropdown" :class="{'is-active': dropdownActive}"> -->
        <div @click="toggleDropdown" class="dropdown" :class="{'is-active': dropdownActive}">
        <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
            <span>Toggle Menu</span>
            <span class="icon is-small">
                <font-awesome-icon v-if="dropdownActive" class="icon" icon="chevron-up" />
                <font-awesome-icon v-else class="icon" icon="chevron-down" />
            </span>
            </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu3" role="menu">
            <div class="dropdown-content">
            <router-link to="user-guide-start" class="dropdown-item">User Guides Home</router-link>
            <hr class="dropdown-divider">
            <router-link to="user-guide-vessels" class="dropdown-item">Adding Vessels</router-link>
            <router-link to="user-guide-fleets" class="dropdown-item">How to use Fleets</router-link>
            <router-link to="user-guide-crew" class="dropdown-item">Adding Crew</router-link>
            <router-link to="user-guide-app" class="dropdown-item">Using Our App</router-link>
            <!-- <router-link to="user-guide-custom-logs" class="dropdown-item">Custom Logs</router-link> -->
            <router-link to="user-guide-obligations" class="dropdown-item">Safety Obligations</router-link>
            <router-link to="user-guide-subscriptions" class="dropdown-item">Subscriptions</router-link>
            </div>
        </div>
        </div>
    </div>


</template>



<script>


export default {

  name: "UserGuideSideMenu",

  props: {
    // source: {
    //   type: String,
    //   required: true
    // },
    sentPath: String,
  },

  data() {
    return {
        dropdownActive: false,
    }
  },

  methods: {

    toggleDropdown: function() {
        this.dropdownActive = !this.dropdownActive;
    },

    toPage(path) {
        let self = this;
        if (this.sentPath == path) {
            console.log('canna do it captain..');
        } else {
            self.$router.push({ 
                path: '/' + path, 
            })
        }
    }

  },

};

</script>


<style lang="scss" scoped>

.dropdown-wrapper {
    display: none;
    .dropdown { 
      width: 100%;
      .dropdown-trigger {
        width: 100%;
        .button {
          width: 100%;
          &:focus {
            outline: none;
            border-color: #dbdbdb;
          }
          .is-small {
            svg {
              font-size: 0.6rem;
            }
          }
        }
      }
      .dropdown-menu {
        width: 100%;
        .dropdown-content {
          .router-link-active {
            background: #eee;
          }
        }
      }
    }
  }


  @media only screen and (max-width: 767px) {

// .container {
//   width: 100%;
// }
// .side-menu {
//   display: none;
// }
.dropdown-wrapper {
  display: block;
}
// .main-content {
//   width: 100%;
//   float: none;
// }

}



@media only screen and (max-width: 550px) {

.main-content {
//   .side-icon {
//     width: 100%;
//     &.left {
//       float: none;
//       padding-right: 0;
//     }
//     &.right {
//       float: none;
//       padding-left: 0;
//     }
//     img {
//       width: 100%;
//       padding: 0;
//     }
//   }
//   .side-text {
//     width: 100%;
//     &.left {
//       float: none;
//     }
//     &.right {
//       float: none;
//     }
//   }
}

}

</style>