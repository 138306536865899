<template>
    <div class="site-wrapper no-header">

        <div class="container">

            <user-guide-side-menu sentPath="user-guide-custom-logs" />

            <user-guide-top-menu sentPath="user-guide-custom-logs" />



            <div class="spacer s30"></div>

            <div class="main-content">

                <div class="notification">
                    <h2>The Power of Custom Logs</h2>
                </div>

                <div class="side-icon left"><img src="@/assets/icons/hand-on-phone.jpg" /></div>
                <div class="side-text right">
                    <h5>The OffshoreSMS is what makes our tools come to life!</h5>
                    <p>If you are a single vessel owner or operator, vessel Master or a crew member on any vessel, then you
                        really only need to be using our app.</p>
                    <p>To download the app, simply go to your App Store and search for "<strong>offshoresms</strong>".</p>
                    <div class="spacer s20"></div>
                    <div class="download centered">
                        <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank"><img
                                class="partners" src="@/assets/img/app-store.jpg" /></a>
                        <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp"
                            target="_blank"><img class="partners" src="@/assets/img/play-store.jpg" /></a>
                    </div>
                </div>
                <div class="clear"></div>

                <hr>
                <div class="side-icon right"><img src="@/assets/icons/devices.jpg" /></div>
                <div class="side-text left">
                    <h5>The app connects vessels to a Fleet Dashboard</h5>
                    <p>If you are using a Fleet to manage your vessels on OffshoreSMS, then every interaction in the app by
                        your Skippers or Crew is synced in real time to the Fleet dashboard.</p>
                    <p>This is where the real magic happens on OffshoreSMS!</p>
                    <p>Send messages in real time to any crew member via the app, and get all logs from the app back from
                        each vessel as they happen!</p>
                </div>
                <div class="clear"></div>

                <hr>

                <div class="buttons">
                    <router-link to="user-guide-obligations" class="button is-primary">Next:&nbsp; Safety Obligations &nbsp;
                        <font-awesome-icon icon="chevron-right" /></router-link>
                </div>



            </div>

            <div class="clear"></div>

        </div>



    </div>
</template>
  
  
<script>

import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import UserGuideSideMenu from "./UserGuideSideMenu.vue";
import UserGuideTopMenu from "./UserGuideTopMenu.vue";

export default {

    name: 'UserGuideApp',
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function () {
        return {
            // dropdownActive: false,

        }
    },
    methods: {

        // toggleDropdown: function () {
        //     this.dropdownActive = !this.dropdownActive;
        // }


    },

}


</script>
  
  
  
  
<style lang="scss" scoped>
.container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
}

.side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;
}

// .dropdown-wrapper {
//     display: none;

//     .dropdown {
//         width: 100%;

//         .dropdown-trigger {
//             width: 100%;

//             .button {
//                 width: 100%;

//                 &:focus {
//                     outline: none;
//                     border-color: #dbdbdb;
//                 }

//                 .is-small {
//                     svg {
//                         font-size: 0.6rem;
//                     }
//                 }
//             }
//         }

//         .dropdown-menu {
//             width: 100%;

//             .dropdown-content {
//                 .router-link-active {
//                     background: #eee;
//                 }
//             }
//         }
//     }
// }

.main-content {
    width: 70%;
    float: left;

    .notification {
        margin-bottom: 3rem;

        h2 {
            font-size: 1.7rem;
            // margin-bottom: 10px;
            text-transform: uppercase;
            color: steelblue;
            font-weight: bold;
        }
    }

    h5 {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: bold;
    }

    p {
        line-height: 1.8rem;

        &.italic {
            font-style: italic;
        }
    }

    hr {
        margin: 2.5rem 0;
    }

    .side-icon {
        width: 25%;

        &.left {
            float: left;
            padding-right: 1rem;
        }

        &.right {
            float: right;
            padding-left: 1rem;
        }

        img {
            width: 100%;
            padding: 0;
        }
    }

    .side-text {
        width: 75%;

        &.left {
            float: left;
        }

        &.right {
            float: right;
        }
    }

    .button {
        &.inline {
            padding: 2px 8px;
            height: auto;
            display: inline-block;
            vertical-align: baseline;
            font-size: 0.9rem;
        }

        &.is-skip {
            background-color: #ff7878;
            color: #fff;
            border-color: #ff7878;
        }
    }
}

ul.features {
    li {
        font-size: 1.1rem;
        margin-bottom: 6px;
        background: #f8f8f8;
        padding: 10px;
        color: steelblue;

        svg {
            font-size: 1.1rem;
        }
    }
}



@media only screen and (max-width: 767px) {

    .container {
        width: 100%;
    }

    .side-menu {
        display: none;
    }

    .dropdown-wrapper {
        display: block;
    }

    .main-content {
        width: 100%;
        float: none;
    }

}



@media only screen and (max-width: 550px) {

    .main-content {
        .side-icon {
            width: 100%;

            &.left {
                float: none;
                padding-right: 0;
            }

            &.right {
                float: none;
                padding-left: 0;
            }

            img {
                width: 100%;
                padding: 0;
            }
        }

        .side-text {
            width: 100%;

            &.left {
                float: none;
            }

            &.right {
                float: none;
            }
        }
    }

}</style>
  
  
  
  
  
  
  