<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

      <user-guide-side-menu sentPath="user-guide-fleets" />

      <user-guide-top-menu sentPath="user-guide-fleets" />



        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>How to use Fleets</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/wheel.jpg" /></div>
          <div class="side-text right">
            <h5>Add unlimited Fleets for Free</h5>
            <p>We've created Fleets as a way of grouping your vessels together. You can add as many vessels as you like from your Dashboard by clicking on the <a class="button is-primary inline">My Fleets</a> tab. Inside each Fleet, add as many vessels as you like, and each vessel comes with a full access 7 Day FREE Trial!</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/template.jpg" /></div>
          <div class="side-text left">
            <h5>The Fleet Dashboard</h5>
            <p>Once you have created your Fleet, then you have full access to the Fleet dashboard, with all of the amazing features that make OffshoreSMS the best Fleet vessel management system there is!</p>
            <p>Use your Fleet dashboard to view your real time activity from all vessels in the fleet, manage vessel subscriptions, view active vessels, track defects, expired safety equipment and heaps more.</p>
          </div>
          <div class="clear"></div>

          <hr>
          <div class="side-icon left"><img src="@/assets/icons/trailer.jpg" /></div>
          <div class="side-text right">
            <h5>Add unlimited vessels to each Fleet</h5>
            <p>Remember that each vessel comes with a full access 7 Day FREE Trial, and any checklists, policies, procedures or any other information added to any vessel will stay there until you decide to activate the 12 month subscription, even after the trial period ends.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/levels.jpg" /></div>
          <div class="side-text left">
            <h5>Add extra Fleet Administrators</h5>
            <p>Once you create a vessel Fleet, you can add as many other OffshoreSMS users as admins to the Fleet.</p>
            <p>Under the Fleet Crew tab (second in the left sidebar), click on the <a class="button is-info inline">Manage Admins</a> button to add and remove admins.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon left"><img src="@/assets/icons/trophy.jpg" /></div>
          <div class="side-text right">
            <h5>We've thought of nearly everything!</h5>
            <p>Below is a list of all features and SMS components that you will have access to for each Fleet vessel, and all for less that <strong>$5 per vessel per year</strong>.</p>
            <p>Simply work your way through each of the sections below for each vessel in your Fleet.</p>
            <p class="italic">(Note that these are dummy buttons to show you the available sections only.)</p>
            <div class="spacer s20"></div>
            <div class="buttons features">
              <span class="button is-dark"><font-awesome-icon icon="list" /> &nbsp; Ship's Log</span>
              <span class="button is-success"><font-awesome-icon icon="ship" /> &nbsp; Vessel Information</span>
              <span class="button is-success"><font-awesome-icon icon="file-signature" /> &nbsp; Responsibility Statements</span>
              <span class="button is-success"><font-awesome-icon icon="exclamation-triangle" /> &nbsp; Vessel Hazards</span>
              <span class="button is-success"><font-awesome-icon icon="users" /> &nbsp; Emergency Contacts</span>
              <span class="button is-success"><font-awesome-icon icon="list" /> &nbsp; Vessel Policies</span>
              <span class="button is-success"><font-awesome-icon icon="anchor" /> &nbsp; Safe Work Procedures</span>
              <span class="button is-success"><font-awesome-icon icon="life-ring" /> &nbsp; Emergency Procedures</span>
              <hr>
              <span class="button is-success"><font-awesome-icon icon="check-circle" /> &nbsp; Startup Checklists</span>
              <span class="button is-success"><font-awesome-icon icon="user" /> &nbsp; Crew Induction Checklists</span>
              <span class="button is-success"><font-awesome-icon icon="users" /> &nbsp; Passenger Inductions</span>
              <span class="button is-success"><font-awesome-icon icon="clipboard-list" /> &nbsp; Trip Activity Checklists</span>
              <span class="button is-success"><font-awesome-icon icon="clipboard-list" /> &nbsp; Shutdown Checklists</span>
              <hr>
              <span class="button is-skip"><font-awesome-icon icon="list" /> &nbsp; Custom Checklists</span>
              <span class="button is-primary"><font-awesome-icon icon="life-ring" /> &nbsp; Drills &amp; Schedules</span>
              <span class="button is-dark"><font-awesome-icon icon="ship" /> &nbsp; Plant &amp; Equipment List</span>
              <span class="button is-light"><font-awesome-icon icon="stopwatch" /> &nbsp; Timed Activities</span>
              <hr>
              <span class="button is-warning"><font-awesome-icon icon="wrench" /> &nbsp; Vessel Defects</span>
              <span class="button is-info"><font-awesome-icon icon="clipboard-list" /> &nbsp; Important Documents</span>
              <span class="button is-skip"><font-awesome-icon icon="calendar-check" /> &nbsp; Important Dates</span>
              <span class="button is-success"><font-awesome-icon icon="life-ring" /> &nbsp; Equipment Register</span>
              <span class="button is-dark"><font-awesome-icon icon="users" /> &nbsp; Passenger Manifests</span>
              <span class="button is-cancel"><font-awesome-icon icon="list" /> &nbsp; SMS Revisions</span>
            </div>
          </div>
          <div class="clear"></div>

          <hr>

          <div class="buttons">
            <router-link to="user-guide-crew" class="button is-primary">Next:&nbsp; Adding Crew &nbsp; <font-awesome-icon icon="chevron-right" /></router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideFleets',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
      // toggleDropdown: function() {
      //   this.dropdownActive = !this.dropdownActive;
      // }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }
  // .dropdown-wrapper {
  //   display: none;
  //   .dropdown { 
  //     width: 100%;
  //     .dropdown-trigger {
  //       width: 100%;
  //       .button {
  //         width: 100%;
  //         &:focus {
  //           outline: none;
  //           border-color: #dbdbdb;
  //         }
  //         .is-small {
  //           svg {
  //             font-size: 0.6rem;
  //           }
  //         }
  //       }
  //     }
  //     .dropdown-menu {
  //       width: 100%;
  //       .dropdown-content {
  //         .router-link-active {
  //           background: #eee;
  //         }
  //       }
  //     }
  //   }
  // }
  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
      &.italic {
        font-style: italic;
      }
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
    .button {
      &.inline {
        padding: 2px 8px;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.9rem;
      }
    }
    .buttons {
      &.features {
        display: block;
        .button {
          display: block;
        }
        hr {
          margin: 1.5rem 0;
        }
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






