<template>

    <div class="side-menu">
          
        <aside class="menu">
        <p class="menu-label">Menu</p>
        <ul class="menu-list">
            <li><a :class="{'is-active': sentPath == 'user-guide-start'}" @click="toPage('user-guide-start')">All User Guides</a></li>
            <li><a :class="{'is-active': sentPath == 'user-guide-vessels'}" @click="toPage('user-guide-vessels')">Adding Vessels</a></li>
            <li><a :class="{'is-active': sentPath == 'user-guide-fleets'}" @click="toPage('user-guide-fleets')">How to use Fleets</a></li>
            <li><a :class="{'is-active': sentPath == 'user-guide-crew'}" @click="toPage('user-guide-crew')">Adding Crew</a></li>
            <li><a :class="{'is-active': sentPath == 'user-guide-app'}" @click="toPage('user-guide-app')">Using Our App</a></li>
            <!-- <li><a :class="{'is-active': sentPath == 'user-guide-custom-logs'}" @click="toPage('user-guide-custom-logs')">Custom Logs</a></li> -->
            <li><a :class="{'is-active': sentPath == 'user-guide-obligations'}" @click="toPage('user-guide-obligations')">Safety Obligations</a></li>
            <li><a :class="{'is-active': sentPath == 'user-guide-subscriptions'}" @click="toPage('user-guide-subscriptions')">Subscriptions</a></li>
        </ul>
        </aside>

    </div>


</template>

<script>


export default {

  name: "UserGuideSideMenu",

  props: {
    // source: {
    //   type: String,
    //   required: true
    // },
    sentPath: String,
  },

  methods: {

    toPage(path) {
        let self = this;
        if (this.sentPath == path) {
            console.log('canna do it captain..');
        } else {
            self.$router.push({ 
                path: '/' + path, 
            })
        }
    }

  },

};

</script>