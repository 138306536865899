<template>
  
  <div class="site-wrapper no-header">

    <div class="container">

      <user-guide-side-menu sentPath="user-guide-obligations" />

      <user-guide-top-menu sentPath="user-guide-obligations" />



        <div class="spacer s30"></div>

        <div class="main-content">

          <div class="notification">
            <h2>Safety Obligations</h2>
          </div>

          <div class="side-icon left"><img src="@/assets/icons/target.jpg" /></div>
          <div class="side-text right">
            <h5>It all starts with Marine Order 504</h5>
            <p>Marine Order 504 is the most important piece of legislation around commercial vessel safety in Australia. It is important that every vessel operator (and skipper and crew) knows that this exists as part of the National Law.</p>
            <p>Every requirement of this Marine Order 504 is covered in the OffshoreSMS platform, and then some.</p>
          </div>
          <div class="clear"></div>
          
          <hr>
          <div class="side-icon right"><img src="@/assets/icons/devices.jpg" /></div>
          <div class="side-text left">
            <h5>There are a number of things that every commercial vessel is required to do to have a compliant safety management system in place.</h5>
            <p>The OffshoreSMS app and the Fleet dashboard covers all of the 12 elements listed in Marine Order 504 as a minimum, but we have gone well beyond that with our additional safety and fleet management tools.</p>
            <p>As a minimum, every vessel operator and Master should do the following:</p>
            <div class="spacer s20"></div>
            <ul class="features">
              <li><font-awesome-icon icon="clipboard-list" />&nbsp; A daily pre-trip safety checklist</li>
              <li><font-awesome-icon icon="users" />&nbsp; Induct all new crew and passengers</li>
              <li><font-awesome-icon icon="wrench" />&nbsp; Log all maintenance done</li>
              <li><font-awesome-icon icon="fire" />&nbsp; Record any incidents or near misses</li>
              <li><font-awesome-icon icon="times" />&nbsp; Record any vessel defects SMS deficiencies and resolve them</li>
              <li><font-awesome-icon icon="check-circle" />&nbsp; Check and maintain a list of all crew and their qualifications</li>
              <li><font-awesome-icon icon="exclamation-triangle" />&nbsp; Do risk assessments of all hazards on the vessel</li>
              <li><font-awesome-icon icon="anchor" />&nbsp; Record the expiry dates of all safety equipment on board</li>
              <li><font-awesome-icon icon="stopwatch" />&nbsp; Perform drills and crew training on a regular basis</li>
              <li><font-awesome-icon icon="ship" />&nbsp; Regularly check and update safe work procedures</li>
              <li><font-awesome-icon icon="life-ring" />&nbsp; Regularly check and update emergency procedures</li>
              <li><font-awesome-icon icon="clipboard-list" />&nbsp; Write and update all vessel and environmental policies</li>
              <li><font-awesome-icon icon="user" />&nbsp; Keep a register of all emergency contacts</li>
              <li><font-awesome-icon icon="pen" />&nbsp; Record statements of authority from the owner, master and designated person</li>
              <li><font-awesome-icon icon="users" />&nbsp; Keep a passenger manifest and do regular head count logs if carrying passengers</li>
            </ul>
            <div class="spacer s20"></div>
          </div>
          <div class="clear"></div>

          <hr>

          <div class="buttons">
            <router-link to="user-guide-subscriptions" class="button is-primary">Next:&nbsp; Subscriptions &nbsp; <font-awesome-icon icon="chevron-right" /></router-link>
          </div>
          


        </div>

        <div class="clear"></div>

      </div>


    
  </div>


</template>


<script>

  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import UserGuideSideMenu from "./UserGuideSideMenu.vue";
  import UserGuideTopMenu from "./UserGuideTopMenu.vue";

  export default {

    name: 'UserGuideObligations',
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
    },
    components: {
        UserGuideSideMenu,
        UserGuideTopMenu
    },
    data: function() {
      return {
        // dropdownActive: false,
        
      }
    },
    methods: {
      
      // toggleDropdown: function() {
      //   this.dropdownActive = !this.dropdownActive;
      // }
      

    },

  }
  

</script>




<style lang="scss" scoped>
  
  .container {
    width: 90%;
    max-width: 1200px;
    // margin-top: 70px;
  }

  .side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;   
  }
  // .dropdown-wrapper {
  //   display: none;
  //   .dropdown { 
  //     width: 100%;
  //     .dropdown-trigger {
  //       width: 100%;
  //       .button {
  //         width: 100%;
  //         &:focus {
  //           outline: none;
  //           border-color: #dbdbdb;
  //         }
  //         .is-small {
  //           svg {
  //             font-size: 0.6rem;
  //           }
  //         }
  //       }
  //     }
  //     .dropdown-menu {
  //       width: 100%;
  //       .dropdown-content {
  //         .router-link-active {
  //           background: #eee;
  //         }
  //       }
  //     }
  //   }
  // }
  .main-content {
    width: 70%;
    float: left;
    .notification {
      margin-bottom: 3rem;
      h2 {
        font-size: 1.7rem;
        // margin-bottom: 10px;
        text-transform: uppercase;
        color: steelblue;
        font-weight: bold;
      }
    }
    h5 {
      font-size: 1.2rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      line-height: 1.8rem;
      &.italic {
        font-style: italic;
      }
    }
    hr {
      margin: 2.5rem 0;
    }
    .side-icon {
      width: 25%;
      &.left {
        float: left;
        padding-right: 1rem;
      }
      &.right {
        float: right;
        padding-left: 1rem;
      }
      img {
        width: 100%;
        padding: 0;
      }
    }
    .side-text {
      width: 75%;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
    .button {
      &.inline {
        padding: 2px 8px;
        height: auto;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0.9rem;
      }
      &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #ff7878;
      }
    }
  }

  ul.features {
    li {
      font-size: 1.1rem;
      margin-bottom: 6px;
      background: #f8f8f8;
      padding: 15px;
      color: dodgerblue;
      svg {
        font-size: 1.1rem;
      }
    }
  }



  @media only screen and (max-width: 767px) {

    .container {
      width: 100%;
    }
    .side-menu {
      display: none;
    }
    .dropdown-wrapper {
      display: block;
    }
    .main-content {
      width: 100%;
      float: none;
    }

  }



  @media only screen and (max-width: 550px) {

    .main-content {
      .side-icon {
        width: 100%;
        &.left {
          float: none;
          padding-right: 0;
        }
        &.right {
          float: none;
          padding-left: 0;
        }
        img {
          width: 100%;
          padding: 0;
        }
      }
      .side-text {
        width: 100%;
        &.left {
          float: none;
        }
        &.right {
          float: none;
        }
      }
    }

  }


</style>






